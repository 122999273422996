import React, {useState, useEffect} from 'react';
import 'font-awesome/css/font-awesome.min.css';
import UploadAudio from './UploadAudio';
import RecordAudio from './RecordAudio';
import personVoice from "../static/images/person-voice.svg";


function AudioBlock({ setAudioPath, setStatusTitle, setTranscription, setProgress, setURL, model_id }) {
  const [isRecording, setIsRecording] = useState(false);
  let [model, setModelId] = useState(model_id);
  
  useEffect(() => {
    setModelId(model_id);
  }, [model_id]);

  return (
    <div className="icons_container">
      <RecordAudio setIsRecording={setIsRecording} setAudioPath={setAudioPath} setStatusTitle={setStatusTitle}
                   setTranscription={setTranscription} setURL={setURL} model_id={model} />
      <UploadAudio isRecording={isRecording} setAudioPath={setAudioPath} setStatusTitle={setStatusTitle}
                   setTranscription={setTranscription} setURL={setURL} setProgress={setProgress} model_id={model} />
    </div>
  )
}

export default AudioBlock;
